/* ====================================================================

   WDS GmbH - Lippstadt / Germany
   https://www.cafe-wien-sylt.de
   Project: Café Wien Sylt | Die Sylter Schokoladenmanufaktur im Cafe Wien auf Sylt
   Author: WDS GmbH | Gordon P. Ries

===================================================================== */

// FONTS
@import 'fonts';

// BOOTSTRAP
@import '~bootstrap/scss/bootstrap';

// VARIABLES
$color-white: #ffffff;
.color-white { color: $color-white !important; }
.bg-color-white { background-color: $color-white !important; }

.link-white {
  color: $color-white;
  text-decoration:none;
  &:hover { color: $color-white; text-decoration:underline; }
}

$color-whisper-white: #fefaf2;
.color-whisper-white { color: $color-whisper-white !important; }
.bg-color-whisper-white { background-color: $color-whisper-white !important; }

$color-black: #000000;
.color-cws-black { color: $color-black }
.bg-cws-black { background-color: $color-black }

$color-cws-red: #dd0229;
.color-cws-red { color: $color-cws-red }
.bg-cws-red { background-color: $color-cws-red }

$color-cws-darkgrey: #3c3c3b;
.color-white { color: $color-cws-darkgrey }
.bg-color-white { background-color: $color-cws-darkgrey }

$color-cws-darkbrown: #513412;
.color-darkbrown { color: $color-cws-darkbrown }
.bg-color-darkbrown { background-color: $color-cws-darkbrown }


html {margin: 0; padding: 0; scroll-behavior: smooth;}


body {
  min-height: 100vh;
  background-color: $color-white;
  font-family: 'Oswald';
  font-size: 18px;
  color: $color-cws-darkgrey;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

.header-nav {
  height: 180px !important;
  background-color:rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  z-index: 999998;
  -moz-box-shadow:0 3px 3px rgba(182, 182, 182, 0.25);
  -webkit-box-shadow: 0 3px 3px rgba(182, 182, 182, 0.25);
  box-shadow: 0 3px 3px rgba(182, 182, 182, 0.25);
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  background-image: url('/images/bg-redstripe.png');
  background-position: right 66px;
  background-repeat: no-repeat;
  background-size: 35vw 50px;

  @include media-breakpoint-down(xl) {
    background-image: none !important;
    height: 116px !important;
  }

}

nav {

  ul {
    max-width: 940px;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    background: $color-cws-red;
    padding: 0px;
    margin: 0px;
    margin-top: 66px;

    li {
      list-style: none;
      margin-right: 12px;
      margin-left: 12px;
      font-size: 25px;
      font-weight: bold;

      &:first-child {
        margin-left: 28px;
     }

      &.active {
        a { color:#ffffff !important; }
      }

      a {
        text-decoration: none;
        color: #fab0b0;
        text-transform: uppercase;
        font-family: 'Oswald';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        transition: 0.3s all;

        &:hover {
          color: $color-white;
        }

        &.active {
           color:#ffffff !important;
        }

      }
    }
  }
}

.background-mood {

  &.start {
    height: 820px;
    width: 100%;
    max-width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50% !important;

    @include media-breakpoint-down(xxl) { height: 820px; }
    @include media-breakpoint-down(xl) { height: 600px; }
    @include media-breakpoint-down(lg) { height: 400px; }
    @include media-breakpoint-down(md) { height: 400px; }
    @include media-breakpoint-down(sm) { height: 400px; }
  }

  &.exclamations {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50% !important;
  }

  &.places-arrow-right, &.places-arrow-left {
    position:relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50% !important;
    background-color: #fefaf2;


    @include media-breakpoint-down(xxl) { height: 475px; }
    @include media-breakpoint-down(xl) { height: 475px; }
    @include media-breakpoint-down(lg) { height: 375px; }
    @include media-breakpoint-down(md) { height: 325px; }
    @include media-breakpoint-down(sm) { height: 225px; }

  }

  &.softicons {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50% !important;
  }

  &.intro-places {
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 245px;
  }

  &.online-shop {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50% !important;
  }

  &.gutscheine {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50% !important;
  }

  &.speisekarten {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50% !important;
  }

  &.kontakt {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50% !important;
  }

  &.places-arrow-right {

    &:before {
      content:"";
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 45px 50px 45px 0;
      border-color: transparent #fefaf2 transparent transparent;
      top: 50%;
      right:0px;
      transform: translateY(-50%);

      @include media-breakpoint-down(lg) {
          content:"";
          position: absolute;
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 50px 50px 50px;
          border-color: transparent transparent #fefaf2 transparent;
          top: auto;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
      }
    }
  }

  &.places-arrow-left {

    &:before {
      content:"";
      position: absolute;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 45px 0 45px 45px;
      border-color: transparent transparent transparent #fefaf2;
      top: 50%;
      left:0px;
      transform: translateY(-50%);

      @include media-breakpoint-down(lg) {
          content:"";
          position: absolute;
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 50px 50px 50px;
          border-color: transparent transparent #fefaf2 transparent;
          top: auto;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);
      }
    }
  }

}

.slider {

  >.slick-dots li {
      margin: 0 4px !important;
  }

  >.slick-dots li button::before {
      opacity: 0 !important;
  }

  >.slick-dots {
      position: absolute !important;
      bottom: 2.5em !important;
      list-style: none;
      text-align: center;
      padding-right: 2em !important;
  }

  >.slick-dots li.slick-active button {
      background-color: white;
      border: 3px solid white;
      transition: 0.4s background-color;
      opacity: 1;
  }

  >.slick-dots li button {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      border: 2px solid rgba(255, 255, 255, 1);
      background-color: rgba(221, 2, 41, 0.5);
      opacity: 1;
  }

}


footer {
  bottom: 0;
  background-color: $color-cws-red;
  color: $color-white;

  & .address {
    font-size: 15px;
  }
}

.button-big-red {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  color:#ffffff;
  padding: 4px 14px 7px 14px;
  background-color: $color-cws-red;
  text-decoration: none !important;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s all;

  &:hover {
    background: #91001a;
    color: #ffffff;
  }

  &.border-white {
    border: 1px solid white !important;
    padding: 4px 18px 7px 18px !important;
  }

  &.w-245 {
    width: 245px;
  }

}

h1 {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  color: $color-cws-darkbrown;
  text-transform: uppercase;
  margin-bottom: 0.3em;
  @include media-breakpoint-down(md) { font-size: 24px; }
}

h2 {
  font-family: 'ThirstyRoughBol';
  font-style: normal;
  font-weight: 400;
  font-size: 58px;
  color: $color-cws-red;
  margin-bottom: 0.5em;
  @include media-breakpoint-down(md) { font-size: 42px; }
}

p {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: $color-cws-darkgrey;
}

.text-cws-intro {
  p {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
  }
}

.places-description {
  background-color: #fefaf2;
  height:475px;

  h5 {
    color: $color-cws-red;
    text-transform: uppercase;
    font-size: 23px;
  }

  h6 {
    margin-top: 25px;
    color: $color-cws-red;
    text-transform: uppercase;
    font-size: 18px;
  }
  p {
    line-height: 25px;
  }

}

.intro-h3-h4 {

  h3 {
    padding-top:1.8em;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    color: $color-cws-darkbrown;
    text-transform: uppercase;
    margin-bottom: 0.6em;

    @include media-breakpoint-down(md) { font-size: 24px; }
  }

  h4 {
    font-family: 'ThirstyRoughBol';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    color: $color-cws-red;
    margin-bottom: 0.5em;

    @include media-breakpoint-down(md) { font-size: 38px; }
  }

  &.white {

    h3 {
      color: #ffffff;
    }

    h4 {
      color: #ffffff;
    }


  }

}

.h5-h6-text-small {

  h5 {
    padding-top:1.8em;
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    color: $color-cws-darkbrown;
    text-transform: uppercase;
    margin-bottom: 0.6em;
  }

  h6 {
    font-family: 'ThirstyRoughBol';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    color: $color-cws-red;
    margin-bottom: 0.5em;
  }

}

.border-2px-grey {
  border: 2px solid #a5a5a5;
}

.link-red {
  color: #dd0229;

  &:hover {
    color: #dd0229;
    text-decoration:underline;
  }
}

.h6-red {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  color: $color-cws-red;
  text-transform: uppercase;
  margin-bottom: 0.6em;
}

.card-jobs {
  background-color: $color-cws-red;
  color: $color-white;
  border: 10px solid $color-cws-red;
  box-shadow: inset 0 0 0 1px $color-white;

  h6 {
    text-transform: normal;
    font-size: 20px;
    line-height: 28px;
    font-weight: 100;
  }

}



.flex-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.input-flex-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
  height: 100px;
  max-width: 1000px;
  position: relative;
  z-index: 0;
}

.input {
  width: 25px;
  height: 25px;
  background-color: $color-cws-red;
  position: relative;
  border-radius: 50%;
}
.input:hover {
  cursor: pointer;
}
.input::before, .input::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  background-color: $color-cws-red;
  width: 4vw;
  height: 5px;
  max-width: 60px;
}
.input::before {
  left: calc(-4vw + 12.5px);
}
.input::after {
  right: calc(-4vw + 12.5px);
}
.input.active {
  background-color: $color-cws-red;
  transition: 0.3s all;
}
.input.active::before {
  background-color: $color-cws-red;
  transition: 0.3s all;
}
.input.active::after {
  background-color: #513412;
}
.input.active span {
  font-weight: 700;
}
.input.active span::before {
  font-size: 13px;
}
.input.active span::after {
  font-size: 18px;
  // color: $color-cws-red;
  text-align: center;
}
.input.active ~ .input, .input.active ~ .input::before, .input.active ~ .input::after {
  background-color: #513412;
}
.input span {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}
.input span::before, .input span::after {
  visibility: visible;
  position: absolute;
  left: 50%;
}
.input span::after {
  content: attr(data-year);
  top: 25px;
  transform: translateX(-50%);
  font-size: 16px;
  text-align: center;
}
.input span::before {
  content: attr(data-info);
  top: -65px;
  width: 70px;
  transform: translateX(-5px) rotateZ(-45deg);
  font-size: 12px;
  text-indent: -10px;
}

.description-flex-container {
  width: 80vw;
  font-weight: 400;
  font-size: 22px;
  margin-top: 3em;
  max-width: 1000px;
}
.description-flex-container p {
  margin-top: 0;
  display: none;
  font-size: 22px;
  font-weight: 300;
  transition: 0.6s all;
}
.description-flex-container p.active {
  display: block;
  transition: 0.6s all;
}

@media (min-width: 1250px) {
  .input::before {
    left: -37.5px;
  }

  .input::after {
    right: -37.5px;
  }
}
@media (max-width: 850px) {
  .input {
    width: 17px;
    height: 17px;
  }
  .input::before, .input::after {
    height: 3px;
  }
  .input::before {
    left: calc(-4vw + 8.5px);
  }
  .input::after {
    right: calc(-4vw + 8.5px);
  }
}
@media (max-width: 600px) {
  .flex-parent {
    justify-content: initial;
  }

  .input-flex-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 5vh;
  }

  .input {
    width: 60px;
    height: 60px;
    margin: 0 10px 50px;
    background-color: #AEB6BF;
  }
  .input::before, .input::after {
    content: none;
  }
  .input span {
    width: 100%;
    height: 100%;
    display: block;
  }
  .input span::before {
    top: calc(100% + 5px);
    transform: translateX(-50%);
    text-indent: 0;
    text-align: center;
  }
  .input span::after {
    top: 50%;
    transform: translate(-50%, -50%);
    color: #ECF0F1;
  }

  .description-flex-container {
    margin-top: 30px;
    text-align: center;
  }
}
@media (max-width: 400px) {
  body {
    min-height: 950px;
  }
}

.box-reservation {

  .card-reservation {

    height: 301px;
    max-width: 240px;
    margin: 0 auto;

    h6 {
      font-family: 'Oswald';
      font-size: 24px;
      color: $color-cws-red;
      font-weight: 400;
      text-transform: uppercase;
    }

    &.bg-white {
      background-color:white !important;
    }
  }

}

#impressum, #datenschutz {
  a { color:$color-cws-red; }
}

#layercws {
    width: 80%;

  h1 {
    font-family: "ThirstyRoughBol";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: #dd0229;
    margin-bottom: 0.5em;
    text-transform: none;
  }
  p {
    font-size: 22px;
  }
}

input, textarea {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  position: relative;
  display: block;
  outline: none;
  margin: 0 auto;
  padding: 10px;
  border: 1px solid #ffffff;
  margin-bottom: 12px;

  color: #ffffff;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
}

input {
  max-width: 360px;
  width:100%;
  height: 40px;
}

input::placeholder, textarea::placeholder {
  color: rgba(250, 250, 250, 0.6);
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
}

.button-form-submit {
  width: 150px;
  height:42px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  color:#ffffff;
  padding: 4px 14px 7px 14px;
  background-color: $color-cws-red;
  text-decoration: none !important;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s all;
  border: none;

  &:hover {
    background: #91001a;
    color: #ffffff;
  }
}

.text-asterisk {
  color: $color-white;
  font-size: 15px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
}
