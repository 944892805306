/* oswald-300 - latin */
@font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 300;
    src: url('fonts/oswald-v48-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('fonts/oswald-v48-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('fonts/oswald-v48-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('fonts/oswald-v48-latin-300.woff') format('woff'), /* Modern Browsers */
         url('fonts/oswald-v48-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('fonts/oswald-v48-latin-300.svg#Oswald') format('svg'); /* Legacy iOS */
  }

  /* oswald-regular - latin */
  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/oswald-v48-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('fonts/oswald-v48-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('fonts/oswald-v48-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('fonts/oswald-v48-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('fonts/oswald-v48-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('fonts/oswald-v48-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
  }

  /* oswald-500 - latin */
  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 500;
    src: url('fonts/oswald-v48-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('fonts/oswald-v48-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('fonts/oswald-v48-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('fonts/oswald-v48-latin-500.woff') format('woff'), /* Modern Browsers */
         url('fonts/oswald-v48-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('fonts/oswald-v48-latin-500.svg#Oswald') format('svg'); /* Legacy iOS */
  }

  /* oswald-600 - latin */
  @font-face {
    font-family: 'Oswald';
    font-style: normal;
    font-weight: 600;
    src: url('fonts/oswald-v48-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('fonts/oswald-v48-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('fonts/oswald-v48-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('fonts/oswald-v48-latin-600.woff') format('woff'), /* Modern Browsers */
         url('fonts/oswald-v48-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('fonts/oswald-v48-latin-600.svg#Oswald') format('svg'); /* Legacy iOS */
  }

  /* thirsty-rough-bol */
  @font-face {
    font-family: 'ThirstyRoughBol';
    font-style: normal;
    font-weight: 300;
    src: url('fonts/ThirstyRoughBol.eot');
    src: local(''),
        url('fonts/ThirstyRoughBol.eot?#iefix') format('embedded-opentype'),
        url('fonts/ThirstyRoughBol.woff2') format('woff2'),
        url('fonts/ThirstyRoughBol.woff') format('woff'),
        url('fonts/ThirstyRoughBol.ttf') format('truetype'),
        url('fonts/ThirstyRoughBol.svg#ThirstyRoughBol') format('svg');
}